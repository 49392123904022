<template>
  <div class="page" :loaded="Boolean(posts && settings)">
    <div class="wrapper" v-if="settings">
      <div class="page-header">
        <div class="page-title">In The News</div>
        <h3>Blog Articles</h3>
        <h5>Find all our latest blog articles</h5>
        <p>You can find all our latest news here and read more of them to stay up to date on our official <a :href="settings.medium" target="_blank">Medium publications</a>.</p>
      </div>
    </div>
    <div class="articles">
      <div class="wrapper">
        <div class="post-container" v-for="(post, i) in posts.slice(0, limit)" :key="post.id">
          <NewsItem :post="post" :size="i > 0 ? 'small' : 'default'" />
        </div>
      </div>
      <div class="load-more" v-if="limit < posts.length">
        <button class="btn" @click="limit = limit + 9">Load more</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon.vue";
import DateFormat from "./DateFormat.vue";
import NewsItem from "./NewsItem.vue";

export default {
  name: "News",
  components: {
    Icon,
    DateFormat,
    NewsItem,
  },
  data() {
    return {
      posts: [],
      limit: 9,
    };
  },
  computed: {
    ...mapGetters({
      settings: "getSettings",
    }),
    db() {
      return this.$store.state.firebase.firestore;
    },
  },
  mounted() {
    this.postsRef = this.db()
      .collection(`${this.testMode ? "test_" : ""}posts`).onSnapshot((snap) => {
        this.posts = snap.docs.map((post) => {
          const data = post.data();
          return {
            ...data,
            pubDate: new Date(data.pubDate),
          };
        }).sort((a, b) => b.pubDate - a.pubDate);
      });
  },
  beforeDestroy() {
    if (this.postsRef) {
      this.postsRef();
    }
  },
};
</script>

<style scoped>
.page-header {
  background: rgba(28, 172, 214, 0.08);
  border-radius: 16px;
  padding: 64px 96px 80px;
  margin: 64px 0;
}

.page-header p a {
  color: #1DACD6;
  text-decoration: underline;
}

.post-container {
  width: 33.3333333%;
  padding: 0 12px;
  margin-bottom: 64px;
}

.articles .wrapper {
  display: flex;
  flex-wrap: wrap;
}

.load-more {
  display: flex;
  justify-content: center;
  margin-bottom: 64px;
}

@media screen and (max-width: 1280px) {
  .post-container {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .page-header {
    margin-top: 0;
    padding: 64px;
  }
}

@media screen and (max-width: 850px) {
  .post-container {
    width: 100%;
    padding: 0;
    margin-bottom: 48px;
  }
}
</style>
